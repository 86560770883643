import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getCameraUniformsDeclaration} from "./CameraUniforms";
import {getObjectUniformsDeclaration} from "./ObjectUniforms";
import ghosting from "../chunks/ghosting.wgsl";
import {getMaterialUniformsDeclaration} from "./MaterialUniforms";
import {getIBLDeclaration} from "./IBL";
import edgeShader from "./edge.wgsl";


export function getEdgeShader(ghosted = false) {
	return $wgsl(edgeShader, {
		ghosting,
		ghosted,
		IBL: getIBLDeclaration(0),
		cameraUniforms: getCameraUniformsDeclaration(1),
		objectUniforms: getObjectUniformsDeclaration(2),
		materialUniforms: getMaterialUniformsDeclaration(3)
	});
}
