import { VBIntersector } from './VBIntersector';
import { FrustumIntersector } from './FrustumIntersector';
import { MeshFlags } from "./MeshFlags";
import { RenderFlags } from "./RenderFlags";
import { logger } from "../../logger/Logger";
import { LmvBox3 as  Box3 } from './LmvBox3';
import { ENABLE_PIXEL_CULLING } from '../globals';

var _tmpBox = new Box3();
var _depths = null;

/**
 * @callback RenderBatch~renderCallback
 * @param {RenderBatch|THREE.Scene} batch - The RenderBatch or THREE.Scene to render.
 */

/**
 * Represents a subset of objects from a larger list, for e.g. a draw call batch
 * to send to the renderer. It's like a small view into an ordered FragmentList.
 */
export class RenderBatch {
    // The model iterator that created this render batch.
    creatorIterator;
    
    frags;

    start;
    count;

    // Defines the (exclusive) range end used in this.forEach(). If a batch is complete, i.e. all fragments are added,
    // we usually have this.lastItem = this.start + this.count. But it may be smaller if dynamic adding is being used.
    // The final value of this.lastItem is set from outside by the creator (see e.g., ModelIteratorLinear or ModelIteratorBVH)
    // NOTE: this.lastItem must be set before this.forEach() has any effect.
    lastItem;

    // Compatibility with THREE.Scene. Optional override material (instanceof THREE.ShaderMaterial) temporarily used by renderers.
    overrideMaterial = null;

    // Whether sort by material ID has been done
    sortDone = false;

    // number of added batches since last material sort
    numAdded = 0;

    // Average time spent for rendering this batch. Maintained externally by RenderScene.renderSome()
    avgFrameTime = undefined;

    // Optional: Unique index of this RenderBatch (used by modelIteratorBVH/ConsolidationIterator)
    nodeIndex = undefined;

    // Summed worldBoxes
    // First 6 terms are the visible bounds, second 6 terms are the hidden bounds
    bboxes = new Array(12);

    //Tells the renderer whether to sort by Z before drawing.
    //We only set this for RenderBatches containing transparent objects.
    sortObjects = false;

    sortByShaderDone = false;

    fragOrderChangedCallbacks = [];

    //Tells the renderer whether to do per-mesh frustum culling.
    //In some cases when we know the whole batch is completely
    //contained in the viewing frustum, we turn this off.
    frustumCulled = true;

    //Used by ground shadow code path
    forceVisible = false;

    renderImmediate;

    //Set per frame during scene traversal
    renderImportance = 0.0;

    // Needed by WebGPU renderer
    isComplete = false;
    useRenderBundles = false;
    _renderBundles = [];

    //Fragment Id of the largest fragment in this batch
    #largestFragId;

    /**
     * Possible types in use for fragOrder.
     * @typedef {(Int32Array|Uint32Array|Array<Int32Array>)} FragOrderTypes
     */

    /**
     * Create a RenderBatch.
     * @param {ModelIteratorLinear|ModelIteratorBVH} creatorIterator - The model iterator that created this render batch.
     * @param {FragmentList}   frags       - FragmentList of all available meshes (1:1 correspondence with LMV fragments)
     * @param {number}         start       - start index in the array of indices
     * @param {number}         count       - how many mesh indices (after start index) are contained in the subset.
     * @param {Uint32Array}   [indices]    - Optional array of indices, if none is specified fragment order will be taken from the iterator
     * @constructor
     */
    constructor(creatorIterator, frags, start, count, indices) {
        this.creatorIterator = creatorIterator;
        this.frags   = frags;
        this.start    = start;
        this.count    = count;
        this.lastItem = start;
        this.indices = indices;

        // The frag list is assumed to already be sorted coming from the server (largest first). 
        // This assumption has been considered unreliable so some code paths migh re-sort locally.
        // Don't use this for critical logic unless you know it is definitely sorted.
        this.#largestFragId = this.getIndices()[this.start];
    
        this.bboxes[0] = this.bboxes[1] = this.bboxes[2] = Infinity;
        this.bboxes[3] = this.bboxes[4] = this.bboxes[5] = -Infinity;
        this.bboxes[6] = this.bboxes[7] = this.bboxes[8] = Infinity;
        this.bboxes[9] = this.bboxes[10] = this.bboxes[11] = -Infinity;

        // FragmentList do not always contain THREE.Meshes for each shape. They may also just contain plain BufferGeometry
        // and THREE.ShaderMaterial. In this case, the renderer must handle the this batch using immediate mode rendering.
        // (see FragmentList.getVizmesh() and WebGLRenderer.render() for details)
        this.renderImmediate = !frags.useThreeMesh;
    }

    clone() {
        const renderBatch = new RenderBatch(this.creatorIterator, this.frags, this.start, this.count, this.indices);
        renderBatch.sortDone = this.sortDone;
        renderBatch.sortByShaderDone = this.sortByShaderDone;
        renderBatch.lastItem = this.lastItem;
        renderBatch.visibleStats = this.visibleStats;
        renderBatch.numAdded = this.numAdded;
        renderBatch.bboxes = this.bboxes.slice();

        return renderBatch;
      }

      // Note: the underlying array might be replaced, so don't keep long-term copies.
      getIndices() {
        return this.indices ?? this.creatorIterator.getFragOrder();
      }

     /**
      * Registers a callback that is invoked when the order in which fragments are rendered changes.
      * @param {function} callback The callback to invoke on the event. Called with the start index and count of the
      *  modified range.
      */
     registerFragOrderChangedCallback = function(callback) {
         this.fragOrderChangedCallbacks.push(callback);
     };

    /**
     * Deregisters a callback that has previously been registered via `registerFragOrderChangedCallback`.
     * @param {function} callback The callback to deregister.
     */
    removeFragOrderChangedCallback = function(callback) {
        const index = this.fragOrderChangedCallbacks.indexOf(callback);

        if (index !== -1) {
            this.fragOrderChangedCallbacks.splice(index, 1);
        }
    };

    sortByMaterial() {
        //Render batch must be complete before we can sort it
        if (this.numAdded < this.count) {
            return;
        }

        var frags   = this.frags;
        var indices = this.getIndices();

        if (!indices) {
            logger.warn("Only indexed RenderSubsets can be sorted.");
            return;
        }

        // apply sort only to the range used by this batch
        var tmp = indices.subarray(this.start, this.start + this.count);
        Array.prototype.sort.call(tmp, function (a,b) {
            var ma = frags.getMaterialId(a);
            var mb = frags.getMaterialId(b);

            if (ma === undefined)
                return mb ? 1 : 0;
            if (mb === undefined)
                return -1;

            return ma - mb;
        });

        //indices.set(tmp, this.start); // not needed because tmp already points to the same buffer

        // indicate that indices are sorted by material and no batches have been added since then.
        this.numAdded = 0;
        this.sortDone = true;

        // Notify listeners about the new order.
        for (const callback of this.fragOrderChangedCallbacks) {
            callback(this.start, this.lastItem - this.start);
        }
    }

      // Sorts meshes in the render batch by shader ID, to avoid unnecessary shader switching in the renderer when looping over a batch.
      // This can only be performed once the RenderBatch is full/complete and all shaders are known.
      sortByShader() {
          //Render batch must be complete before we can sort it
          if (!this.sortDone || this.sortByShaderDone)
              return;

          var frags = this.frags;
          var indices = this.getIndices();

          var tmp = indices.subarray(this.start, this.start + this.count);

          Array.prototype.sort.call(tmp, function (a,b) {
              var ma = frags.getMaterial(a);
              var mb = frags.getMaterial(b);

              var pd = ma.program.id - mb.program.id;
              if (pd)
                  return pd;

              return ma.id - mb.id;
          });

          this.numAdded = 0;
          this.sortByShaderDone = true;
      }

      sortByVertexBuffer(start, end) {

        //Render batch must be complete before we can sort it
        if (this.sortByVBDone)
            return;

        let frags = this.frags;
        let indices = this.getIndices();

        let tmp = indices.subarray(start, end);

        //TODO: sort by underlying GPUBuffer id, to minimize buffer switching
        Array.prototype.sort.call(tmp, function (a,b) {
            let ga = frags.getGeometry(a);
            let gb = frags.getGeometry(b);

            let vbida = ga ? ga.__gpuvb?.id : Number.MAX_SAFE_INTEGER;
            let vbidb = gb ? gb.__gpuvb?.id : Number.MAX_SAFE_INTEGER;

            if (vbida === undefined || vbidb === undefined) {
                console.log("sorting too early");
            }

            if (vbida < vbidb) {
                return -1;
            } else if (vbida > vbidb) {
                return 1;
            }

            let ibida = ga ? ga.__gpuib?.id : Number.MAX_SAFE_INTEGER;
            let ibidb = gb ? gb.__gpuib?.id : Number.MAX_SAFE_INTEGER;

            if (ibida < ibidb) {
                return -1;
            } else if (ibida > ibidb) {
                return 1;
            }

            let matIda = frags.getMaterial(a)?.id ?? Number.MAX_SAFE_INTEGER;
            let matIdb = frags.getMaterial(b)?.id ?? Number.MAX_SAFE_INTEGER;

            return matIda - matIdb;
            //TODO: we need to cache the WebGPU pipeline hash in order to use it here --
            //multiple materials can map to the same hash
            //return mata.__gpuPipelineHash - matb.__gpuPipelineHash;
        });

        //indices.set(tmp, this.start);

        this.numAdded = 0;
        this.sortByVBDone = true;
        this.sortDone = true;

        // Notify listeners about the new order.
        for (const callback of this.fragOrderChangedCallbacks) {
            callback(this.start, this.lastItem - this.start);
        }
      }

      // Sorts indices in order of decreasing depth for the current view.
      // Input: frustumIn instanceof FrustumIntersector
      sortByDepth(frustumIn) {
          var frags   = this.frags;
          var indices = this.getIndices();
          var frustum = frustumIn;
          var bbox    = _tmpBox;

          if (!indices) {
              logger.warn("Only indexed RenderSubsets can be sorted.");
              return;
          }

          // allocate this.depth to store a depth value for each fragment index in indicesView
          if (!_depths || _depths.length < this.count)
              _depths = new Float32Array(this.count);

          var depths = _depths;
          var start = this.start;

          // For each fragId indicesView[i], compute the depth and store it in depth[i]
          this.forEachNoMesh(
              (fragId, i) => { // use frustum to calculate depth per fragment
                  if (!frags.hasGeometry(fragId))
                      depths[i] = -Infinity;
                  else {
                      frags.getWorldBounds(fragId, bbox);
                      depths[i] = frustum.estimateDepth(bbox);
                  }
              }
          );

          // Insertion sort appears to be about 7x or more faster
          // for lists of 64 or less objects vs. defining a sort() function.
          // TODO Asking if there's a faster way. Traian mentioned quicksort > 8 objects; I might give this a try.
          var tempDepth, tempIndex;
          for ( var j = 1; j < this.count; j++ ) {
              var k = j;
              while ( k > 0 && depths[k-1] < depths[k] ) {

                  // swap elem at position k one position backwards (for indices and depths)
                  tempDepth   = depths[k-1];
                  depths[k-1] = depths[k];
                  depths[k]   = tempDepth;

                  tempIndex   = indices[start+k-1];
                  indices[start+k-1] = indices[start+k];
                  indices[start+k]   = tempIndex;

                  k--;
              }
          }

          // Notify listeners about the new order.
          for (const callback of this.fragOrderChangedCallbacks) {
              callback(this.start, this.lastItem - this.start);
          }
      }

      // Adds the given THREE.Box3 to the renderBatch bounding box or hidden object bounding box
      addToBox(box, hidden) {
          var offset = hidden ? 6 : 0;
          var bb = this.bboxes;
          bb[0+offset] = Math.min(bb[0+offset], box.min.x);
          bb[1+offset] = Math.min(bb[1+offset], box.min.y);
          bb[2+offset] = Math.min(bb[2+offset], box.min.z);

          bb[3+offset] = Math.max(bb[3+offset], box.max.x);
          bb[4+offset] = Math.max(bb[4+offset], box.max.y);
          bb[5+offset] = Math.max(bb[5+offset], box.max.z);
      }

      getBoundingBox(dst) {
          dst = dst || _tmpBox;
          var bb = this.bboxes;
          dst.min.x = bb[0];
          dst.min.y = bb[1];
          dst.min.z = bb[2];

          dst.max.x = bb[3];
          dst.max.y = bb[4];
          dst.max.z = bb[5];

          return dst;
      }

      getBoundingBoxHidden(dst) {
          dst = dst || _tmpBox;
          var bb = this.bboxes;
          var offset = 6;
          dst.min.x = bb[0+offset];
          dst.min.y = bb[1+offset];
          dst.min.z = bb[2+offset];

          dst.max.x = bb[3+offset];
          dst.max.y = bb[4+offset];
          dst.max.z = bb[5+offset];

          return dst;
      }

      // Use only for incremental adding to linearly ordered (non-BVH) scenes!
      onFragmentAdded(fragId) {
          // update bbox
          this.frags.getWorldBounds(fragId, _tmpBox);
          this.addToBox(_tmpBox, false);

          // mark
          this.sortDone = false;

          // NOTE: This only works with trivial fragment ordering (linear render queues).
          // Otherwise the item index does not necessarily match the fragId due to the reordering jump table (this.getIndices()).
          if (this.lastItem <= fragId) {
              this.lastItem = fragId + 1;
              if (this.visibleStats !== undefined)
                  this.visibleStats = 0; // reset visibility, since a new fragment might change it
              }
          this.numAdded++;
      }

    /**
     * Iterates over fragments.
     * @param {function} callback - function(mesh, id) called for each fragment geometry.
     *      - mesh: instanceof THREE.Mesh (as obtained from FragmentList.getVizmesh)
     *      - id:   fragment id
     * @param {number} drawMode - Optional flag (see FragmentList.js), e.g., MESH_VISIBLE. If specified, we only traverse fragments for which this flag is set.
     * @param {bool} includeEmpty - Default: false, i.e. fragments are skipped if they have no mesh available via getVizmesh().
     */
    forEach(callback, drawMode, includeEmpty) {
        // TODO Remove once resource management work is done, where we use this information for debugging
        this.numFragsStreamingDraw = 0;

        var indices = this.getIndices();
        var frags = this.frags;
        var sortByShaderPossible = !this.sortByShaderDone;

        // If the most likely rendering flags are true, use a shortened version of the for-loop.
        var i, iEnd, idx, m;
        if (!drawMode && !includeEmpty && !sortByShaderPossible) {
            for (i=this.start, iEnd=this.lastItem; i<iEnd; i++) {
                idx = indices ? indices[i] : i;

                m = frags.getVizmesh(idx);

                if (m && m.geometry) {
                    if (m.geometry.streamingDraw)
                        this.numFragsStreamingDraw++;                    
                    callback(m, idx);
                }
            }
        } else {
            const hasDrawStart = Object.prototype.hasOwnProperty.call(this, "drawStart");
            for (i = (drawMode === MeshFlags.MESH_RENDERFLAG && hasDrawStart) ? this.drawStart : this.start, iEnd=this.lastItem; i<iEnd; i++) {
                idx = indices ? indices[i] : i;

                m = frags.getVizmesh(idx);

                if (sortByShaderPossible && (!m || !m.material || !m.material.program))
                    sortByShaderPossible = false;

                // if drawMode is given, iterate vizflags that match
                if ((includeEmpty || (m && m.geometry)) &&
                    (!drawMode || frags.isFlagSet(idx, drawMode))) {

                    if (m.geometry.streamingDraw)
                        this.numFragsStreamingDraw++;
                    
                    callback(m, idx);
                }
            }
        }

        // If all materials shaders are already available, we can sort by shader to minimize shader switches during rendering.
        // This sort will only execute once and changing materials later will break the sorted order again.
        if (sortByShaderPossible) {
            this.sortByShader();
        }
    }

    is2d() {
        return this.frags.is2d;
    }

    /**
     * Iterates over fragments.
     * @param {function} callback - function(mesh, id, idx) called for each fragment geometry.
     *      - mesh: instanceof THREE.Mesh (as obtained from FragmentList.getVizmesh)
     *      - id:   fragment id
     *      - idx:  fragment index in the RenderBatch
     * @param {Number} startIndex - Optional start iteration at a specific index (used for loop pause/continuation)
     * @param {Number} loopLimit - Optional maximum number of items to loop over before stopping*
     */
    forEachWGPU(startIndex, loopLimit, callback) {

        const drawMode = (this.forceVisible ? MeshFlags.MESH_VISIBLE : MeshFlags.MESH_RENDERFLAG) | 0;
        const indices = this.getIndices();
        const frags = this.frags;

        let isComplete = true;

        const start = startIndex || this.start;
        let iEnd = this.lastItem;
        if (loopLimit) {
            iEnd = Math.min(iEnd, start + loopLimit);
        }

        //Super ugly inlined version of FragmentList.getVizmesh()
        const geomsGeoms = frags.geoms.geoms;
        const vizflags = frags.vizflags;
        const geomids = frags.geomids;
        const materialIdMap = frags.materialIdMap;
        const materialids = frags.materialids;

        let count = 0;
        let i;

        let is2d = this.is2d();

        if (!this.isComplete) {
            for (i = start; i<iEnd; i++) {
                const fragId = indices[i] | 0;

                // init temp mesh object from geometry, material etc.
                const geometry = is2d ? frags.vizmeshes[fragId]?.geometry : geomsGeoms[geomids[fragId]];

                if (!geometry) {
                    const geomDataId = frags.getGeometryId(fragId);
                    const fragLoading = frags.isFlagSet(fragId, MeshFlags.MESH_LOADING);
                    if (fragLoading || geomDataId !== 0) {
                        // Some geometries will never arrive. If the geomDataId is 0, we know it doesn't exist.
                        // Don't mark as incomplete in this case.
                        isComplete = false;
                    }
                    continue;
                }

                if (!geometry.__gpuvb) {
                    isComplete = false;
                }

                const flags = vizflags[fragId];

                if (!(flags & drawMode)) {
                    continue;
                }

                if (is2d) {
                    // In 2D, there is one mesh per fragment
                    let mesh = frags.vizmeshes[fragId];
                    if (mesh) {
                        callback(mesh);
                    }
                } else {
                    callback(geometry, materialIdMap[materialids[fragId]], i);
                }

                count++;
            }

            if (isComplete) {
                this.isComplete = true;
                this.useRenderBundles = !this.sortObjects;
            }
        } else {
            if (!this.sortByVBDone) {
                // This would ideally be done once in the isComplete block above.
                // But sorting the fragments after invoking the render callback messes with the
                // fragment -> uniform buffer location association. It's just a single check run once per batch though,
                // and the runtime should start to predict / skip it fairly quickly.
                this.sortByVertexBuffer(start, iEnd);
            }

            for (i = start; i<iEnd; i++) {

                const fragId = indices[i];

                const flags = vizflags[fragId];

                if (!(flags & drawMode)) {
                    continue;
                }

                if (is2d) {
                    // In 2D, there is one mesh per fragment
                    let mesh = frags.vizmeshes[fragId];
                    if (mesh) {
                        callback(mesh);
                    }
                } else {
                    callback(geomsGeoms[geomids[fragId]], materialIdMap[materialids[fragId]], i);
                }
                count++;
            }
        }

        return i === this.lastItem ? 0 : i;
    };

    setRenderBundle(index, renderBundle) {
        this._renderBundles[index] = renderBundle;
    };

    getRenderBundle(index) {
        return this._renderBundles[index];
    };

    clearRenderBundles() {
        this._renderBundles.length = 0;
    }

    /**
     * Iterates over fragments. Like forEach(), but takes a different callback.
     * @param {function} callback - function(fragId, idx) called for each fragment geometry.
     *      - fragId:   fragment id
     *      - idx:      running index from 0 .. (lastItem-start)
     * @param {number} drawMode - Optional flag (see FragmentList.js), e.g., MESH_VISIBLE. If specified, we only traverse fragments for which this flag is set.
     * @param {bool} includeEmpty - Default: false, i.e. fragments are skipped if they have no mesh available via getVizmesh().
     */
    forEachNoMesh(callback, drawMode, includeEmpty) {
        var indices = this.getIndices();
        var frags = this.frags;

        for (var i=this.start, iEnd=this.lastItem; i<iEnd; i++) {
            var fragId = indices ? indices[i] : i;

            // if drawMode is given, iterate vizflags that match
            if ((includeEmpty || frags.hasGeometry(fragId)) && (!drawMode || frags.isFlagSet(fragId, drawMode))) {
                callback(fragId, i-this.start);
            }
        }
    }

    /**
     * Checks if given ray hits a bounding box of any of the fragments.
     * @param {THREE.RayCaster} raycaster
     * @param {Object[]}        intersects - An object array that contains intersection result objects.
     *                                       Each result r stores properties like r.point, r.fragId, r.dbId. (see VBIntersector.js for details)
     * @param {number[]}       [dbIdFilter] - Array of dbIds. If specified, only fragments with dbIds inside the filter are checked.
     * @param {Object}         [options]    - Raycast options.
     */
    raycast(raycaster, intersects, dbIdFilter, options) {
        // Assumes bounding box is up to date.
        if (raycaster.ray.intersectsBox(this.getBoundingBox()) === false) {
            return;
        }

        // traverse all visible meshes
        this.forEach((m, fragId) => {
            // Don't intersect hidden objects
            if (this.frags.isFlagSet(fragId, MeshFlags.MESH_HIDE)) {
                return;
            }

            // Check the dbIds filter if given
            if (dbIdFilter && dbIdFilter.length) {
                //Theoretically this can return a list of IDs (for 2D meshes)
                //but this code will not be used for 2D geometry intersection.
                var dbId = 0 | this.frags.getDbIds(fragId);

                // dbIDs will almost always have just one integer in it, so indexOf should be fast enough.
                if (dbIdFilter.indexOf(dbId) === -1) {
                    return;
                }
            }

            // raycast worldBox first.
            this.frags.getWorldBounds(fragId, _tmpBox);

            // Expand bounding box a bit, to take into account axis aligned lines
            _tmpBox.expandByScalar(0.5);

            if (raycaster.ray.intersectsBox(_tmpBox)) {
                // worldbox was hit. do raycast with actual geometry.
                VBIntersector.rayCast(m, raycaster, intersects, options);
            }

        }, MeshFlags.MESH_VISIBLE);
    }

    /**
     * Checks if a given FrustumIntersector hits the bounding box of any of the fragments. Calls the callback if it does.
     * @param {FrustumIntersector}  frustumIntersector
     * @param {Function}            callback - callback function to receive fragment IDs which intersect or are contained by the frustum
     * @param {Boolean}             [containmentKnown] - true if it's already known that the RenderBatch is fully contained by the frustum
     */
    intersectFrustum(frustumIntersector, callback, containmentKnown) {
        if (!containmentKnown) {
            let result = frustumIntersector.intersectsBox(this.getBoundingBox());
            if (result === FrustumIntersector.OUTSIDE) {
                return;
            }
            if (result === FrustumIntersector.CONTAINS) {
                containmentKnown = true;
            }
        }

        // traverse all visible meshes
        this.forEach((m, fragId) => {
            // Don't intersect hidden objects
            if (this.frags.isFlagSet(fragId, MeshFlags.MESH_HIDE))
                return;

            if (containmentKnown) {
                callback(fragId, containmentKnown);
                return;
            }

            // raycast worldBox first.
            this.frags.getWorldBounds(fragId, _tmpBox);

            let result = frustumIntersector.intersectsBox(_tmpBox);
            if (result !== FrustumIntersector.OUTSIDE) {
                callback(fragId, result === FrustumIntersector.CONTAINS);
            }
        }, MeshFlags.MESH_VISIBLE);
    }

    /**
     * Computes/updates the bounding boxes of this batch.
     */
    calculateBounds() {
        // init boxes for visible and ghosted meshes
        this.bboxes[0] = this.bboxes[1] = this.bboxes[2] = Infinity;
        this.bboxes[3] = this.bboxes[4] = this.bboxes[5] = -Infinity;
        this.bboxes[6] = this.bboxes[7] = this.bboxes[8] = Infinity;
        this.bboxes[9] = this.bboxes[10] = this.bboxes[11] = -Infinity;

        // Why including null geometry?: If we would exclude fragments whose geometry is not loaded yet, we would need to refresh all bboxes permanently during loading.
        // Since we know bboxes earlier than geometry (for SFF at FragmentList construction time and for Otg as soon as BVH data is available), including empty meshes
        // ensures that the bbox result is not affected by geometry loading state for 3D.
        this.forEachNoMesh(fragId => {
        // adds box of a fragment to bounds or bounds, depending on its vizflags.
            this.frags.getWorldBounds(fragId, _tmpBox);

            const vizflags = this.frags.vizflags;
            var f = vizflags[fragId];

            this.addToBox(_tmpBox, !(f&1/*MESH_VISIBLE*/));
        }, 0, true);
    }

    /**
     * Updates visibility for all fragments of this RenderBatch.
     * This means:
     *  1. It returns true if all meshes are hidden (false otherwise)
     *
     *  2. If the whole batch box is outside the frustum, nothing else is done.
     *     (using this.getBoundingBox() or this.getBoundingBoxHidden(), depending on drawMode)
     *
     *  3. For all each checked fragment with fragId fid and mesh m, the final visibility is stored...
     *      a) In the m.visible flag.
     *      b) In the MESH_RENDERFLAG of the vizflags[fid]
     *     This is only done for fragments with geometry.
     * @param {number} drawMode - One of the modes defined in RenderFlags.js, e.g. RENDER_NORMAL
     * @param {FrustumIntersector} frustum
     * @returns {bool} True if all meshes are hidden (false otherwise).
     */
    applyVisibility(drawModeIn, frustumIn) {
        let frags;
        let vizflags;
        let frustum;
        let drawMode;
        let checkContainment; // indicates if the batch is completely inside the frustum
        let allHidden;
        let doNotCut; // do not apply cutplanes
        let useRenderBundles;

        /**
         * Checks if fragment is outside the frustum.
         * @param {number} idx - index into frags.
         * @returns {bool} True if the given fragment is outside the frustum and culling is enabled.
         */
        function evalCulling(idx) {
            var culled = false;

            frags.getWorldBounds(idx, _tmpBox);
            if (checkContainment && !frustum.intersectsBox(_tmpBox)) {
                culled = true;
            }

            if (ENABLE_PIXEL_CULLING && !culled && frustum.estimateProjectedDiameter(_tmpBox) < frustum.areaCullThreshold) {
                culled = true;
            }

            // apply cutplane culling
            // TODO We ignore checkContainment, because checkContainment is set to false if the RenderBatch is fully inside the frustum - which still tells nothing about the cutplanes.
            // Ideally, we should a corresponding hierarchical check per cutplane too.
            if (!culled && !doNotCut && frustum.boxOutsideCutPlanes(_tmpBox)) {
                culled = true;
            }

            return culled;
        }

        /**
         * Sets the MESH_RENDERFLAG for a single fragment, depending on the drawMode and the other flags of the fragment.
         * @param {number} idx - index into vizflags, for which we want to determine the MESH_RENDERFLAG.
         * @param {bool} hideLines
         * @param {bool} hidePoints
         * @returns {bool} Final, evaluated visibility.
         */
        function evalVisibility(idx, hideLines, hidePoints) {
            let isFragVisible = false;

            // Strange bug in MS Edge when the debugger is active. Down below where we or in the MESH_RENDERFLAG, ~MESH_RENDERFLAG was getting used in stead.
            // Copying the value to a local variable fixed the issue.
            const rflag = MeshFlags.MESH_RENDERFLAG;
            const vfin = vizflags[idx] & ~rflag;

            switch (drawMode) {
                case RenderFlags.RENDER_HIDDEN:
                    // visible (bit 0 on)
                    isFragVisible = !(vfin & MeshFlags.MESH_VISIBLE);
                    break;
                case RenderFlags.RENDER_HIGHLIGHTED:
                    // highlighted (bit 1 on)
                    isFragVisible = vfin & MeshFlags.MESH_HIGHLIGHTED;
                    break;
                default:
                    // visible but not highlighted, and not a hidden line (bit 0 on, bit 1 off, bit 2 off)
                    isFragVisible = (vfin & (MeshFlags.MESH_VISIBLE|MeshFlags.MESH_HIGHLIGHTED|MeshFlags.MESH_HIDE)) == 1;
                    break;
            }

            if (hideLines) {
                const isLine = vfin & (MeshFlags.MESH_ISLINE | MeshFlags.MESH_ISWIDELINE);
                isFragVisible = isFragVisible && !isLine;
            }

            if (hidePoints) {
                const isPoint = (vfin & MeshFlags.MESH_ISPOINT);
                isFragVisible = isFragVisible && !isPoint;
            }

            // Store evaluated visibility into bit 7 of the vizflags to use for immediate rendering
            vizflags[idx] = vfin | (isFragVisible ? rflag : 0);

            return isFragVisible;
        }

        // Callback to apply visibility for a single fragment
        //
        // Input: Geometry and index of a fragment, i.e.
        //  m:   instanceof THREE.Mesh (see FragmentList.getVizmesh). May be null.
        //  idx: index of the fragment in the fragment list.
        //
        // What is does:
        //  1. bool m.visible is updated based on flags and frustum check (if m!=null)
        //  2. The MESH_RENDERFLAG flag is updated for this fragment, i.e., is true for meshes with m.visible==true
        //  3. If there is no geometry and there is a custom callback (checkCull)
        //  4. Set allHidden to false if any mesh passes as visible.
        function applyVisCB(mesh, idx) {
            // if there's no mesh or no geometry, just call the custom callback.
            // TODO it would be clearer to remove the frags.useThreeMesh condition here.
            // It's not really intuitive that for (m==0) the callback is only called for frags.useThreeMesh.
            // Probably the reason is just that this code section has just been implemented for the useThreeMesh case and the other one was irrelevant.
            if ((!mesh && frags.useThreeMesh) || (!mesh.geometry)) {
                return;
            }

            // apply frustum check for this fragment
            const culled = evalCulling(idx);

            // if outside, set m.visible and the MESH_RENDERFLAG of the fragment to false
            if (culled) {
                if (mesh) {
                    mesh.visible = false;
                } else {
                    logger.warn("Unexpected null mesh");
                }
                // unset MESH_RENDERFLAG
                vizflags[idx] = vizflags[idx] & ~MeshFlags.MESH_RENDERFLAG;

                return;
            }

            // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
            // Note that evalVisibility also updates the MESH_RENDERFLAG already.
            const visible = evalVisibility(idx, frags.linesHidden, frags.pointsHidden);

            if (mesh) {
                mesh.visible = !!visible;
            }

            // Set to false if any mesh passes as visible
            allHidden = allHidden && !visible;
        }

        // Similar to applyVisCB above, but without geometry param, so that we don't set any m.visible property.
        function applyVisCBNoMesh(idx) {

            // No need to check if there is a geometry for the fragment.
            // forEachNoMesh is called with includeEmpty=false, so this callback won't be invoked if there is none.

            // [HB:] Adopted from Tandem - note that useRenderBundles can only be true for WebGPU
            //
            // We need to skip per fragment culling when using render bundles.
            // If bundles have already been recorded, this is just a performance optimization, because we don't render
            // individual fragments anyway (but just replay the previously recorded bundle).
            // If a bundle is currently recorded, we want to include all fragments of a batch, because they wouldn't show
            // if the bundle was recorded without them and they get into the frustum later.
            if (!useRenderBundles) {
                // apply frustum check for this fragment
                const culled = evalCulling(idx);

                // if culled, set visflags MESH_RENDERFLAG to false
                if (culled) {
                    vizflags[idx] = vizflags[idx] & ~MeshFlags.MESH_RENDERFLAG;
                    return;
                }
            }

            // frustum check passed. But it might still be invisible due to vizflags and/or drawMode.
            // Note that evalVisibility also updates the MESH_RENDERFLAG already.
            const visible = evalVisibility(idx, frags.linesHidden, frags.pointsHidden);

            // Set to false if any mesh passes as visible
            allHidden = allHidden && !visible;
        }

        // Used when parts of the same scene have to draw in separate passes (e.g. during isolate).
        // TODO Consider maintaining two render queues instead if the use cases get too complex, because this approach is not very scalable as currently done:
        // It traverses the entire scene twice, plus the flag flipping for each item.

        allHidden = true;
        frustum   = frustumIn;
        drawMode  = drawModeIn;

        var bbox = (drawMode === RenderFlags.RENDER_HIDDEN) ? this.getBoundingBoxHidden() : this.getBoundingBox();

        // Check if the entire render batch is contained inside  the frustum. This will save per-object checks.
        var containment = frustum.intersectsBox(bbox);
        if (containment === FrustumIntersector.OUTSIDE) {
            return allHidden; // nothing to draw
        }

        // check if the whole batch is too small
        if (ENABLE_PIXEL_CULLING && frustum.estimateProjectedDiameter(bbox) < frustum.areaCullThreshold) {
            return allHidden;
        }

        doNotCut = this.frags.doNotCut;
        if (!doNotCut && frustumIn.boxOutsideCutPlanes(bbox)) {
            return allHidden;
        }

        vizflags = this.frags.vizflags;
        frags = this.frags;
        checkContainment = containment !== FrustumIntersector.CONTAINS;
        useRenderBundles = this.useRenderBundles;

        // The main difference between applyVisCB and applyVisCBNoMesh is that applyVisCB also updates mesh.visible for each mesh.
        // This does only make sense when using THREE.Mesh. Otherwise, the mesh containers are volatile anyway (see FragmentList.getVizmesh)
        if (!frags.useThreeMesh) {
            // Use callback that does not set mesh.visible
            this.forEachNoMesh(applyVisCBNoMesh, 0, false);
        } else {
            // Use callback that also sets mesh.visible.
            // Skip fragments without geometry unless a custom callback is defined (fragIdCB)
            this.forEach(applyVisCB, null);
        }

        frags = null;

        return allHidden;
    }

    /**
     * Takes a rendering callback that should be called with everything that the RenderBatch contains.
     * @param {RenderBatch~renderCallback} renderCB - Render Callback
     */
    render(renderCB) {
        renderCB(this);
    }

    /**
     * Returns the bounding box of the largest fragment in this batch.
     * @param {Three.Box3} dst 
     */
    getLargestFragBoxThree = (dst) => {
        var off = this.#largestFragId * 6;
        var src = this.frags.boxes;
        dst.min.x = src[off];
        dst.min.y = src[off + 1];
        dst.min.z = src[off + 2];
        dst.max.x = src[off + 3];
        dst.max.y = src[off + 4];
        dst.max.z = src[off + 5];
    };
}

/**
 * A RenderBatch that contains a consolidated scene and a list of fragments that are not rendered
 * via consolidation or instancing.
 */
export class ConsolidatedRenderBatch extends RenderBatch {
    constructor(iterator, originalRenderBatch, consolidation) {
        const indices = new Int32Array(consolidation.nodeId2SingleFragIds?.[originalRenderBatch.nodeIndex]);
        super(iterator, originalRenderBatch.frags, 0, indices.length, indices);

        this.lastItem = this.count;
        this.bboxes = originalRenderBatch.bboxes;
        this.consolidatedScene = new THREE.Scene();
        this.sortDone = false;
    }

    /**
     * Takes a rendering callback that should be called with everything that the RenderBatch contains.
     * @param {RenderBatch~renderCallback} renderCB - Render Callback
     */
    render(renderCB) {
        renderCB(this.consolidatedScene);
        renderCB(this);
    }
}
