import { VERTEX_BUFFER_REGION_SIZE, INDEX_BUFFER_REGION_SIZE } from '../globals.js';

/**
 * Information about a subset of a larger webgl buffer that is used by a LeanBufferGeometry.
 */
export class BufferSubset {
  /**
   * Creates a new buffer subset.
   * 
   * @param {BufferRegion} bufferRegion - Buffer region that contains this subset.
   * @param {number} offset - Offset of the subset in the buffer region.
   * @param {number} size - Size of the subset in bytes.
   */
  constructor(bufferRegion, offset, size) {
      this.bufferRegion = bufferRegion;
      this.offset = offset;
      this.size = size;
  }

  /**
   * Returns the WebGL buffer that contains this subset.
   * @returns {WebGLBuffer} The WebGL buffer that contains this subset.
   */
  getGlBuffer() {
      return this.bufferRegion._buffer;
  }

  /**
   * Returns the stride of this subset
   *
   * @returns {number} The stride of the buffer subset.
   */
  getStride() {
      return this.bufferRegion._stride;
  }
}

/**
 * Represents one region that has been allocated on the GPU which contains multiple individual buffers.
 */
class BufferRegion {
  /**
   * Creates a new buffer region.
   * 
   * @param {WebGLRenderingContext} gl - The WebGL context.
   * @param {number} size - The size of the buffer region in bytes.
   * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
   * @param {number} stride - The stride of the buffer region.
   */
  constructor(gl, size, type, stride) {
      this._buffer = gl.createBuffer();
      this._size = size;
      this._subsets = [];
      this._freeSpace = size;
      this._isVertexBuffer = type === gl.ARRAY_BUFFER;
      this._stride = stride;

      gl.bindBuffer(type, this._buffer);
      gl.bufferData(type, size, gl.STATIC_DRAW);
  }

  /**
   * Tries to allocate a buffer subset of the given size.
   * If there is a gap large enough to fit the new subset, the space will be used.
   * Otherwise, allocation will fail.
   *
   * @param {number} size - The size of the buffer subset.
   * @returns {BufferSubset|null} - The newly allocated buffer subset or null on failure.
   */
  tryAllocateBufferSubset(size) {
      if (size > this._freeSpace) {
          return null;
      }

      // Search through list of subsets and check if there is a gap large enough to fit the new subset.
      // If there is a gap, we can reuse the space.
      let offset = 0;
      for (let i = 0; i < this._subsets.length; i++) {
          const subset = this._subsets[i];
          const gap = subset.offset - offset;
          if (gap >= size) {
              const newSubset = new BufferSubset(this, offset, size);
              this._subsets.splice(i, 0, newSubset);
              this._freeSpace -= size;
              return newSubset;
          }
          offset = subset.offset + subset.size;
      }

      if (offset + size > this._size) {
          return null;
      }

      // If there is no gap large enough, we can only allocate at the end of the buffer.
      const newSubset = new BufferSubset(this, offset, size);
      this._subsets.push(newSubset);
      this._freeSpace -= size;

      return newSubset;
  }

  /**
   * Destroys the buffer region and frees the GPU memory.
   * @param {WebGLRenderingContext} gl - The WebGL context.
   */
  destroy(gl) {
      gl.deleteBuffer(this._buffer);
      this._buffer = null;
  }
}
/**
 * This class manages WebGl vertex and index buffers. It combines multiple buffers into a single buffer
 * and keeps track of the offsets of each buffer. This is done to make memory management on the GPU
 * more efficient.
 */
export class BufferManager {
  /**
   * Creates a Buffer manager instance
   * @param {WebGLRenderingContext} gl - WebGl context
   */
  constructor(gl) {
      this._gl = gl;
      this._indexBuffers = [];
      this._vertexBuffers = [];
  }

  /**
   * Allocates a new buffer region of the given type.
   * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
   * @param {number} minSize - The minimum size of the buffer region.
   * @param {number} stride - The stride of the buffer region.
   * @returns {BufferRegion} - The newly allocated buffer region.
   */
  allocateNewBufferRegion(type, minSize, stride) {
    const isVertexBuffer = type === this._gl.ARRAY_BUFFER;
    const allocatedBuffers = isVertexBuffer ? this._vertexBuffers : this._indexBuffers;
    const bufferSize = Math.max(minSize, isVertexBuffer ? VERTEX_BUFFER_REGION_SIZE : INDEX_BUFFER_REGION_SIZE);

    console.assert(bufferSize % 4 === 0, "Buffer size must be a multiple of 4");

    const newBuffer = new BufferRegion(this._gl, bufferSize, type, stride);
    allocatedBuffers.push(newBuffer);
    return newBuffer;
  }

  /**
   * Tries to allocate a buffer subset of the given size and stride.
   * If no buffer region with the given stride and enough free space
   * exists, a new one will be allocated.
   *
   * @param {number} size - The size of the buffer subset.
   * @param {number} type - The type of the buffer (gl.ARRAY_BUFFER or gl.ELEMENT_ARRAY_BUFFER)
   * @param {number} stride - The stride of the buffer subset.
   * @returns {BufferSubset} - The newly allocated buffer subset.
   */
  allocateBufferSubset(size, type, stride) {
    const isVertexBuffer = type === this._gl.ARRAY_BUFFER;
    const allocatedBuffers = isVertexBuffer ? this._vertexBuffers : this._indexBuffers;

    // Search through list of buffers and try to allocate a subset in one of them.
    // Note: this does a linear search from the beginning of the buffer list. We
    // could potentially optimize this by keeping information about the available
    // free spaces in the buffers.
    // However, at the moment, this does not seem to be a large performance bottleneck,
    // during uploading only 2.7% of the time had been spent in this function.
    for (let i = 0; i < allocatedBuffers.length; i++) {
      if (allocatedBuffers[i]._stride === stride) {
          const subset = allocatedBuffers[i].tryAllocateBufferSubset(size);
          if (subset) {
              return subset;
          }
      }
    }

    // If no buffer has enough space, allocate a new one.
    const newBuffer = this.allocateNewBufferRegion(type, size, stride);

    // Allocate a subset in the new buffer.
    return newBuffer.tryAllocateBufferSubset(size);
  }

  /**
   * Frees a buffer subset.
   * If the buffer region that contains the subset is empty after freeing the subset,
   * it will be destroyed.
   *
   * @param {BufferSubset} subset - The buffer subset to free.
   */
  freeBufferSubset(subset) {
    subset.bufferRegion._freeSpace += subset.size;
    subset.bufferRegion._subsets.splice(subset.bufferRegion._subsets.indexOf(subset), 1);

    // If the buffer region is empty, remove it from the list of allocated buffers and destroy it.
    if (subset.bufferRegion._subsets.length === 0) {
        const isVertexBuffer = subset.bufferRegion._isVertexBuffer;
        const allocatedBuffers = isVertexBuffer ? this._vertexBuffers : this._indexBuffers;
        allocatedBuffers.splice(allocatedBuffers.indexOf(subset.bufferRegion), 1);
        subset.bufferRegion.destroy(this._gl);
    }
  }

  /**
   * Remove all buffers from the cache (since those were all deallocated when the context was lost)
   */
  resetAfterContextLoss() {
    this._vertexBuffers = [];
    this._indexBuffers = [];
  }

  /**
   * Prints some statistics about the memory usage of the buffer manager.
   */
  printMemoryStatistics() {
    console.log("Vertex Buffer Count: ", this._vertexBuffers.length);
    console.log("Memory in Vertex Buffers: ", this._vertexBuffers.reduce( (a,b) => {
      this._gl.bindBuffer(this._gl.ARRAY_BUFFER, b._buffer);
      const size = this._gl.getBufferParameter(this._gl.ARRAY_BUFFER, this._gl.BUFFER_SIZE);
      return a + size;
    }, 0) / 1024);
    console.log("Actually allocated memory in Vertex Buffers: ", this._vertexBuffers.reduce( (a,b) => {
        return a + b._size - b._freeSpace;
    }, 0));

    console.log("Index Buffer Count: ", this._indexBuffers.length);
    console.log("Memory in Index Buffers: ", this._indexBuffers.reduce( (a,b) => {
        this._gl.bindBuffer(this._gl.ELEMENT_ARRAY_BUFFER, b._buffer);
        const size = this._gl.getBufferParameter(this._gl.ELEMENT_ARRAY_BUFFER, this._gl.BUFFER_SIZE);
        return a + size;
    }, 0) / 1024);

    console.log("Actually allocated memory in Index Buffers: ", this._indexBuffers.reduce( (a,b) => {
        return a + b._size - b._freeSpace;
    }, 0));
  }
}
