import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";

import pack_depth from "../chunks/pack_depth.wgsl";
import colorShader from "./color.wgsl";
import groundShadowShader from "./ground_shadow.wgsl";
import blurFragment from "./blur_fragment.wgsl";

import { getIBLDeclaration } from "../main/IBL";
import { getCameraUniformsDeclaration } from "../main/CameraUniforms";
import { getObjectUniformsDeclaration } from "../main/ObjectUniforms";
import { getTextureDeclaration } from "./GroundShadowTextures";

export function getGroundShadowShader(iblBindingIndex, cameraBindingIndex, objectUniformBindingIndex) {
	return $wgsl(groundShadowShader, {
		IBL: getIBLDeclaration(iblBindingIndex),
		cameraUniforms: getCameraUniformsDeclaration(cameraBindingIndex),
		objectUniforms: getObjectUniformsDeclaration(objectUniformBindingIndex),
		pack_depth

	});
}

export function getBlurFragmentShader(horizontal, textureBindingIndex) {
	return $wgsl(blurFragment, {
		pack_depth,
		groundShadowTexture: getTextureDeclaration(textureBindingIndex),
		horizontal,
	});
}

export function getColorShader(cameraBindingIndex, textureBindingIndex) {
	return $wgsl(colorShader, {
		cameraUniforms: getCameraUniformsDeclaration(cameraBindingIndex),
		groundShadowTexture: getTextureDeclaration(textureBindingIndex),
		pack_depth
	});
}
