import {BumpAllocator} from "./BumpAllocator";

export class VertexBuffer extends BumpAllocator {

	#curVB;
	#curIB;
	#curPass;
	#renderer;
	#device;

	constructor(renderer) {
		const device = renderer.getDevice();
		super(device);
		this.#renderer = renderer;
		this.#device = device;
	}

	#disposeHandler(event) {
		const geometry = event.target;
		if (geometry.__gpu) {
			// The event listener class overrides the this context when invoking this handler.
			// So we need to access the vertex buffer instance via geometry.__gpu.
			const that = geometry.__gpu;
			that.gFree(geometry);
			geometry.removeEventListener('dispose', that.#disposeHandler);
		}
	}

	/**
	 * Free up vertex buffer allocatations for this geometry, if there are any.
	 * @param {Object} geometry
	 * @param {VertexBuffer|undefined} geometry.__gpu
	 */
	static deallocateGeometry(geometry) {
		if (geometry.__gpu) {
			geometry.__gpu.gFree(geometry);
		}
	}

	initVB(geometry) {
		if (geometry.__gpu && !geometry.vbNeedsUpdate) {
			return;
		}

		// If we've initialized this VertexBuffer already we free up the GPU memory
		// we used previously.
		// @todo: We could instead detect if the previously used buffers are the same size
		// as what we want this time, and reuse them if so. This would save a small amount
		// processing time, and potentially reduce some fragmentation of the buffer.
		VertexBuffer.deallocateGeometry(geometry);

		//This is done earlier in getBufferLayout
		//if (!geometry.vb) {
			//create interleaved buffer, if not present
		//}

		geometry.__gpu = this;

		let strideBytes = geometry.vbstride * 4;
		let [vb, baseVertex] = this.vAlloc(geometry.vb.byteLength, strideBytes);
		this.#device.queue.writeBuffer(vb.buffer, baseVertex * strideBytes, geometry.vb.buffer, geometry.vb.byteOffset, geometry.vb.byteLength);

		geometry.__gpuvb = vb;
		geometry.__gpuvbSize = geometry.vb.byteLength;
		geometry.__gpuvbBaseVertex = baseVertex;

		let len4 = geometry.ib.byteLength;
		if (len4 % 4) len4+=2;

		let lenLines = geometry.iblines?.byteLength || 0;
		if (lenLines % 4) lenLines += 2;

		let [ib, baseIndexOffset] = this.iAlloc(len4 + lenLines);

		if (geometry.ib.byteOffset + len4 > geometry.ib.buffer.byteLength) {
			console.log("OUT OF BOUNDS FIX", ib.offset, len4);
			//This is an annoying case where the underlying array buffer doesn't have extra bytes to
			//compensate for the extra 2 bytes we add to the copy length to make it multiple of 4
			//Note that only triangle index buffers can be non-multiple of 4.
			let tmp = new Uint16Array(new ArrayBuffer(len4));
			tmp.set(geometry.ib);
			this.#device.queue.writeBuffer(ib.buffer, baseIndexOffset, tmp.buffer, 0, len4);
		} else {
			this.#device.queue.writeBuffer(ib.buffer, baseIndexOffset, geometry.ib.buffer, geometry.ib.byteOffset, len4);
		}

		if (geometry.iblines) {
			this.#device.queue.writeBuffer(ib.buffer, baseIndexOffset + len4,
				geometry.iblines.buffer, geometry.iblines.byteOffset, lenLines);
		}

		geometry.__gpuib = ib;

		if (geometry.ib instanceof Uint16Array) {
			geometry.__gpuibType = "uint16";
			geometry.__gpuibShift = 1;
			geometry.iblines && (geometry.__gpuibLinesOffset = len4 / 2);
		} else if (geometry.ib instanceof Uint32Array) {
			geometry.__gpuibType = "uint32";
			geometry.__gpuibShift = 2;
			geometry.iblines && (geometry.__gpuibLinesOffset = len4 / 4);
		} else {
			console.warn("unknown index buffer type");
		}

		geometry.__gpuibBaseIndex = baseIndexOffset >> geometry.__gpuibShift;
		geometry.__gpuibSize = len4 + lenLines;

		geometry.vbNeedsUpdate = false;

		geometry.addEventListener('dispose', this.#disposeHandler);

		// This is useful to debug memory leaks.
		// console.log(geometry.id, this.computeUsedBytes());
	}

	draw(passEncoder, geometry, instanceId) {

		if (!geometry.__gpuvb || geometry.vbNeedsUpdate) {
			this.initVB(geometry);
		}

		const vb = geometry.__gpuvb;
		const ib = geometry.__gpuib;

		if (this.#curPass !== passEncoder) {
			passEncoder.setVertexBuffer(0, vb.buffer);
			this.#curVB = vb.buffer;

			passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
			this.#curIB = ib.buffer;

			this.#curPass = passEncoder;
		} else {
			if (vb.buffer !== this.#curVB) {
				passEncoder.setVertexBuffer(0, vb.buffer);
				this.#curVB = vb.buffer;
			}

			if (ib.buffer !== this.#curIB) {
				passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
				this.#curIB = ib.buffer;
			}
		}

		passEncoder.drawIndexed(geometry.ib.length, 1, geometry.__gpuibBaseIndex, geometry.__gpuvbBaseVertex, instanceId);
	}

	drawEdges(passEncoder, geometry, instanceId) {

		if (!geometry.iblines) {
			return;
		}

		if (!geometry.__gpuvb) {
			this.initVB(geometry);
		}

		const vb = geometry.__gpuvb;
		const ib = geometry.__gpuib;

		if (this.#curPass !== passEncoder) {
			passEncoder.setVertexBuffer(0, vb.buffer);
			this.#curVB = vb.buffer;

			passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
			this.#curIB = ib.buffer;

			this.#curPass = passEncoder;
		} else {
			if (vb.buffer !== this.#curVB) {
				passEncoder.setVertexBuffer(0, vb.buffer);
				this.#curVB = vb.buffer;
			}

			if (ib.buffer !== this.#curIB) {
				passEncoder.setIndexBuffer(ib.buffer, geometry.__gpuibType);
				this.#curIB = ib.buffer;
			}
		}

		let firstIndex = geometry.__gpuibBaseIndex + geometry.__gpuibLinesOffset;
		passEncoder.drawIndexed(geometry.iblines.length, 1, firstIndex, geometry.__gpuvbBaseVertex, instanceId);
	}

}
