
import {DepthFormat} from "../CommonRenderTargets";
import {getBufferLayout2D, getPipelineHash2D} from "../Pipelines";
import {getLineShaderSS} from "./LineShaderSS";

export class LinePipeline {

	#renderer;
	#device;

	#pipelines = new Map();
	#activePipeline;
	#activeBindGroupLayout;
	#activeTargetsList;
	#vb;

	#getPipelineHash2D;

	constructor(renderer) {
		this.#renderer = renderer;
		this.#device = renderer.getDevice();
		this.#vb = this.#renderer.getVB();
		this.#getPipelineHash2D = getPipelineHash2D;
	}

	#createPipeline(key, geometry, material) {

		let pipeline;

		let shader = this.#device.createShaderModule({
			code: getLineShaderSS(material),
		});

		pipeline = this.#device.createRenderPipeline({
			layout: this.#device.createPipelineLayout({
				bindGroupLayouts: this.#activeBindGroupLayout
			}),
			vertex: {
				module: shader,
				entryPoint: 'vsmain',
				buffers: getBufferLayout2D(geometry),
			},
			fragment: {
				module: shader,
				entryPoint: 'psmain',
				targets: this.#activeTargetsList,
			},
			primitive: {
				topology: 'triangle-list',
				cullMode: 'none',
			},

			depthStencil: {
				depthWriteEnabled: material.depthWrite,
				depthCompare: material.depthTest ? (material.depthFunc || 'less-equal') : "always",
				format: DepthFormat,
			},
		});

		this.#pipelines.set(key, pipeline);

		return pipeline;
	}

	reset(layouts, targets) {
		this.#activePipeline = null;
		this.#activeBindGroupLayout = layouts;
		this.#activeTargetsList = targets;
	}

	drawOne(passEncoder, objectIndex, geometry, material) {

		const key = this.#getPipelineHash2D(geometry, material);

		let pipeline = this.#pipelines.get(key);
		if (!pipeline) {
			pipeline = this.#createPipeline(key, geometry, material);
		}

		if (pipeline !== this.#activePipeline) {
			passEncoder.setPipeline(pipeline);
			this.#activePipeline = pipeline;
		}

		this.#vb.draw(passEncoder, geometry, objectIndex);
	}

}
