import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getIBLDeclaration} from "../main/IBL";
import {getCameraUniformsDeclaration} from "../main/CameraUniforms";
import {getObjectUniformsDeclaration} from "../main/ObjectUniforms";
import {getLineUniformsDeclaration} from "./LineUniforms";
import hatch from "../chunks/hatch_pattern.wgsl";
import lineShader from "./lineSS.wgsl";

export function getLineShaderSS(material) {
	return $wgsl(lineShader, {
		IBL: getIBLDeclaration(0),
		cameraUniforms: getCameraUniformsDeclaration(1),
		objectUniforms: getObjectUniformsDeclaration(2),
		lineUniforms: getLineUniformsDeclaration(3),
		hatch,
		materialHasLineStyles: material.hasLineStyles
	});
}
