import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getBlendSettingsDeclaration} from "./BlendSettings";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import blendShader from "./blend.wgsl";


export function getBlendShader() {
	return $wgsl(blendShader, { blendUniforms: getBlendSettingsDeclaration(1), tonemap, hatch });
}
