import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import {getCameraUniformsDeclaration} from "./CameraUniforms";
import {getObjectUniformsDeclaration} from "./ObjectUniforms";
import {getIBLDeclaration} from "./IBL";
import line3DShader from "./line3D.wgsl";

export function getLine3DShader() {
	return $wgsl(line3DShader, {
		IBL: getIBLDeclaration(0),
		cameraUniforms: getCameraUniformsDeclaration(1),
		objectUniforms: getObjectUniformsDeclaration(2),
	});
}
