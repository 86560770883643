
import {$wgsl} from "../../wgsl-preprocessor/wgsl-preprocessor";
import pack_normals from "../chunks/pack_normals.wgsl";
import pack_depth from "../chunks/pack_depth.wgsl";
import mat_inverse from "../chunks/mat-inverse.wgsl";
import {getIBLDeclaration} from "./IBL";
import {getEnvMapSampleChunk} from "../chunks/env_sample";
import tonemap from "../chunks/tonemap.wgsl";
import hatch from "../chunks/hatch_pattern.wgsl";
import {getCameraUniformsDeclaration} from "./CameraUniforms";
import {getObjectUniformsDeclaration} from "./ObjectUniforms";
import {getMaterialUniformsDeclaration} from "./MaterialUniforms";
import heatmap from "../chunks/heatmap.wgsl";
import heatmapDecl from "../chunks/heatmap_decl.wgsl";
import uberShader from "./uber.wgsl";

export function getUberShader(material, hasUV, hasTextures, hasVertexColors, isUVW) {
	return $wgsl(uberShader, {
		IBL: getIBLDeclaration(0),
		cameraUniforms: getCameraUniformsDeclaration(1),
		objectUniforms: getObjectUniformsDeclaration(2),
		materialUniforms: getMaterialUniformsDeclaration(3),
		pack_normals,
		pack_depth,
		mat_inverse,
		tonemap,
		envMapSample: getEnvMapSampleChunk(true, false, true),
		hatch,
		heatmapDecl,
		heatmap,
		metalMaterial: material.metal,
		hasUV,
		hasTextures,
		hasVertexColors,
		isUVW,
		hasUVAndHasVertexColors: hasUV && hasVertexColors,
		hasUVAndNotHasVertexColors: hasUV && !hasVertexColors,
		notHasUVAndHasVertexColors: !hasUV && hasVertexColors
	});
}
